import React, { Component } from 'react'
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";export default class BitlyLink extends Component {
    constructor(props) {
        super(props);
        this.state = {linkResponse: ''};
      }
    
    componentDidMount(){

console.log("oooooo"+this.props.link)
        fetch('https://api-ssl.bitly.com/v4/shorten', {
    method: 'POST',
    headers: {
        'Authorization': "Bearer 9149c779711c33c33f7e70185fa46b98b07e2716",
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "long_url": this.props.link, "domain": "bit.ly"})
}).then(response=>{
    response.json().then(res=>{
        this.setState({linkResponse:res.link})
    })
});

    }
    render() {
        return (
            <div>
                    <Card sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {this.state.linkResponse}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            Link Here
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image="https://bit.ly/3zrlsCC"
        alt="qrcode"
      />
    </Card>
            </div>
        )
    }
}
