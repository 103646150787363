import firebase from 'firebase/compat/app'
import 'firebase/compat/database'; // If using Firebase database
import 'firebase/compat/storage'; 

const firebaseApp = firebase.initializeApp(
{
    apiKey: "AIzaSyDjQGpR7OMACTnRJ4_jel_dgCIwuAzlJoM",
    authDomain: "sevkiyatqr.firebaseapp.com",
    projectId: "sevkiyatqr",
    storageBucket: "sevkiyatqr.appspot.com",
    messagingSenderId: "580162736567",
    appId: "1:580162736567:web:e76086785f769ed1d7d832"
      }
);


const storage = firebase.storage();

export {storage}
