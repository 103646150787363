import logo from './logo.svg';
import './App.css';
import React,{useState,ReactDOM,useRef, useEffect} from 'react';
import BitlyLink from './BitlyLink';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { findRenderedDOMComponentWithClass } from 'react-dom/test-utils';
import QRCode from 'easyqrcodejs';
import { FileUploader } from 'react-drag-drop-files';
import { storage } from './firebase';
function App() {
  const [responseLink, setResponseLink] = useState("")
  const [linkCreated, setLinkCreated] = useState(false);
  const [image , setImage] = useState('');
const qrCodeRef = useRef(null);
  const pdfLink = "https://firebasestorage.googleapis.com/v0/b/plise-b4b79.appspot.com/o/favicong.ico?alt=media&token=bc3fee97-1702-4b3d-bf87-5e35d0c7f451";
  const valueLinkRef= useRef("")
  var qrObj = null;
  const upload = ()=>{
    console.log("asdsd",image)
    const ref = storage.ref("/reports/").child(image.name);

    // [START storage_upload_blob]
    // 'file' comes from the Blob or File API
    ref.put(image).then((snapshot) => {
    ref.getDownloadURL().then((url)=>{
        convertLink(url,createQrEasy);
      })

    });
  }

  const handleFile = image=>{
    setImage(image);
    
  };
useEffect(() => {
  setImage(image);
})
 
 function convertLink(linktoShort,callback){
   
let linkConverted ="";
   fetch('https://api-ssl.bitly.com/v4/shorten', {
method: 'POST',
headers: {
   'Authorization': "Bearer 9149c779711c33c33f7e70185fa46b98b07e2716",
   'Content-Type': 'application/json'
},
body: JSON.stringify({ "long_url": linktoShort, "domain": "bit.ly"})
}).then(response=>{
response.json().then(res=>{
  console.log(res);
   setResponseLink(res.link)
   callback(res.link);
  })
});


 }
 const finishProcess=()=>{
// var printContents = document.getElementById("qrCodeDiv").firstChild.toDataURL("image/png");
// console.log(printContents)
var printContents = document.getElementById("qrCodeDiv").innerHTML;
var originalContents = document.body.innerHTML;

document.body.innerHTML = printContents;

window.print();

document.body.innerHTML = originalContents;
}


const createQrEasy=(linkowac)=>{
  console.log("linkowac",linkowac)
  var options_object = {
    // ====== Basic
    drawer: "png",
    text: linkowac,
    width: 800,
    height: 800,
    format:'PNG',
    logo:"https://globalwindow.pl/wp-content/uploads/2020/03/Globalwindowlogo.jpg",
    logoWidth: 100, // width. default is automatic width
      logoHeight: 100, // height. default is automatic height
      logoBackgroundColor: "#fff",
      logoBackgroundTransparent: false,
      width: 300,
      height: 300,
      correctLevel: QRCode.CorrectLevel.Q
}
    new QRCode(qrCodeRef.current,options_object);
    
}



 const createQr=(linkowac)=>{
   console.log("linkowac"+linkowac)
   var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/png',
    quality: 1,
    margin: 10,
  
  }
QRCode.toDataURL(linkowac,opts)
.then(url => {
  var img = `<img src="${url}"></img>`;
  document.getElementById('qrCodeDiv').innerHTML=img;
})
.catch(err => {
  console.error(err)
})
 }
 
//  const runPromise=()=>{
//   new Promise(function(resolve,reject){
//     upload();
//     console.log("primis",link)
//       if(link!=null){
//         resolve(convertLink);
//       }else{
//         reject("upload was not successful")
//       }
//     }).then(
//       function(value) {},
//       function(error) {alert(error);}
//     );
//  }



  return (
    <div className="App">
      <header className="App-header">
      <div>
        {/* <TextField inputRef={valueLinkRef} id="pdfLink" label="Link here" variant="outlined" /> */}
        <center>
      <FileUploader handleChange={handleFile} name="file" types={["JPG","PNG", "PDF"]} />
      </center>
      </div>
      <Button id="submitButton" variant="contained" onClick={upload} color="success"> Gonder Gelsin</Button>
      <Button id="print" className="button" variant="contained"  onClick={finishProcess} color="error"> Print</Button>
      
      <div id="resultQr" ref={qrCodeRef}>
        <h1><a href={responseLink}>{responseLink}</a></h1>
     
      <div id="qrCodeDiv" >
      
      </div>
      </div>
      </header>
    </div>
  );
}

export default App;
